import { useEffect, useState } from "react";

export function Edit({ value, onSave, type }) {
   const isHeading = type == "heading"
   const [editing, setEditing] = useState(false);
   const [val, setVal] = useState(value);
   useEffect(() => {
      setVal(value);
   }, [value]);

   function handleBtn(){
      if (editing) onSave(val);
      setEditing(!editing);
   }

   return (
      <div className="edit">
         <span onClick={handleBtn} className="edit-btn">{editing?<>&#10003;</>:<>&#9998;</>}</span>
         {editing ? (isHeading ? <input
            value={val}
            onChange={(e) => setVal(e.target.value)}
         /> : <textarea
            value={val}
            onChange={(e) => setVal(e.target.value)}
         ></textarea>) : (isHeading ? <h2>{val}</h2> : <p style={{whiteSpace:"pre-line"}}>{val}</p>)}
      </div>
   );
}
