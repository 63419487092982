import {  Outlet } from "react-router-dom";
import { logout } from "../../services/auth";
import "../../styles/sidebar.css";

export function SideBar({children, title,onThemeChange}) {
      let items = children.filter((child) => !!(child.props.link??false));
      return <>
            <div className="sidebar">
                  <h2>{title}</h2>
                  <div className="sidebar-items">
                        {items}
                  </div>
                  <div className="sidebar-btns">
                  <button className="turtle-button"onClick={logout}>Sign Out</button>
                        <button className="turtle-button" id="theme-btn" onClick={onThemeChange}>Toggle Theme</button>
                  </div>
            </div>
            <div className="content">
                  <Outlet></Outlet>
            </div>
            </>

      ;
}