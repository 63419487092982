import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import * as auth from "./auth";
import { db } from "./db";

export var currentStartup = null;

export async function init(){
   return await loadStartup()
}

export async function setStartup(name, desc, location, coFounderName, coFounderEmail, coFounderPhone, website) {
   if (currentStartup && (name === currentStartup.name && desc === currentStartup.desc)) return;
   const uid = auth.currentUser.uid;

   const setFn = currentStartup ? updateDoc : setDoc;
   const data = currentStartup ? { ...currentStartup, name, desc, location, coFounderName, coFounderEmail, coFounderPhone, website } : {
      name: name || "",
      desc: desc || "",
      location: location || "",
      coFounderName: coFounderName || "",
      coFounderEmail: coFounderEmail || "",
      coFounderPhone: coFounderPhone || "",
      website: website || "",
      owneruid: uid,
      timeline: []
   };

   return await (setFn(doc(db, "startups", uid), data).then(() => {
      currentStartup = data;
   }));
}

export async function updateStartupInfo(data){
   if (!currentStartup) return;
   let uid = auth.currentUser.uid;
  
   let _d = {...currentStartup, ...data};

   return await(updateDoc(doc(db, "startups", uid), _d).then(() => {
      currentStartup = _d;
   }));
}



async function loadStartup(){
   let uid = auth.currentUser.uid;
   try {
      const result = await getDoc(doc(db, "startups", uid));
      if (result.exists()) {
         currentStartup = result.data();
         console.log("Startup loaded");
         return currentStartup
      } else {
         currentStartup = null;
         console.log("No startup!");
      }
   } catch (error) {
      console.log("Error getting document:", error);
      return null;
   }
}


export async function getStartup(uid){
   try {
      const result = await getDoc(doc(db, "startups", uid));
      if (result.exists()) {
         return result.data();
      } else {
         console.log("No startup!");
         return null;
      }
   } catch (error) {
      console.log("Error getting document:", error);
      return null;
   }
}
