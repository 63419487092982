import "../styles/main.css";
import EnvImage from "../static/assets/env.png";
import SocialImage from "../static/assets/social.png";
import GovImage from "../static/assets/gov.png";
import HealthImage from "../static/assets/health.png";
import EntreImage from "../static/assets/entre.png";
import GeneralImage from "../static/assets/general.png";

function EnvironmentButton() {
   return (
      <a href="https://drive.google.com/drive/folders/1CQ2WvIy0sUgqcQp8uM2dok7nHZEv-jJt?usp=sharing" target="_blank" style={{ textDecoration: "none", display: "inline-block" }}><h2 className="header">Environment</h2><button className="buttons" id="env"><img src={EnvImage} style={{ width: allButtons.imageSize }} /></button></a>
   );
}

function SocialButton() {
   return (
      <a href="https://drive.google.com/drive/folders/1en9iFssoxthuVu_VNMDHhtn6nB2CpoQC?usp=sharing" target="_blank" style={{ textDecoration: "none", display: "inline-block" }}><h2 className="header">Social</h2><button className="buttons" id="social"><img src={SocialImage} style={{ width: allButtons.imageSize }} /></button></a>
   );
}


function GovernanceButton() {
   return (
      <a href="https://drive.google.com/drive/folders/17-V7W-N0ql_TrM3EKOQBxbS7pns2I6m_?usp=sharing" target="_blank" style={{ textDecoration: "none", display: "inline-block" }}><h2 className="header">Governance</h2><button className="buttons" id="gov"><img src={GovImage} style={{ width: allButtons.imageSize }} /></button></a>
   );
}


function HealthButton() {
   return (
      <a href="https://drive.google.com/drive/folders/1lrn0VtTLEpMGGDyuqhwYNmbrzo6EFSfD?usp=sharing" target="_blank" style={{ textDecoration: "none", display: "inline-block" }}><h2 className="header">Health</h2><button id="health" className="buttons"><img src={HealthImage} style={{ width: allButtons.imageSize }} /></button></a>
   );

}


function EntreButton() {
   return (
      <a href="https://drive.google.com/drive/folders/16JBcTU-yl4Uidl0oZ0iz7bbErlyc5d1k?usp=sharing" target="_blank" style={{ textDecoration: "none", display: "inline-block" }}><h2 className="header" id="entre-header">Entrepreneurship</h2><button className="buttons" ><img src={EntreImage} style={{ width: allButtons.imageSize }} id="entre" /></button></a>
   );
}


function GeneralButton() {
   return (
      <a href="https://drive.google.com/drive/folders/1El6m5PQxXcRgZk4OXCKnQSTBH3-e6pmL?usp=sharing" target="_blank" style={{ textDecoration: "none", display: "inline-block" }}><h2 className="header" id="general-header">General</h2><button className="buttons" id="general"><img src={GeneralImage} style={{ width: allButtons.imageSize }} /></button></a>
   );
}


const allButtons = {
   imageSize: 50,
};




export function Resources() {
   return (
      <div>
         <h1 className="resources-title"> Resources </h1>
         <EnvironmentButton />
         <SocialButton />
         <GovernanceButton />
         <HealthButton />
         <EntreButton />
         <GeneralButton />

      </div>
   );


}