import React, { useEffect } from 'react'
import Posts from './Posts';
import {  QUERY_SEARCH_POSTS } from '../Graphql/Queries';
import { useQueryPosts } from '../hooks/useQueryPosts';

export function SearchResult (){
    const {posts, error } = useQueryPosts({query: QUERY_SEARCH_POSTS});
    if(error) return <h2 style={{textAlign: 'center'}}>{error}</h2>
    return(
        <React.Fragment>
            <Posts posts={posts} />
        </React.Fragment>
    )
}
