import { useRef } from "react";
import "../styles/home.css"
import { ImageCycle } from "./ImageCycle"
import { Link } from "react-router-dom";

export function Home() {
   const navlist = useRef();
   return (
      <div id="h-main">
         <nav id="h-navbar">
            <img id="h-nav-logo" src={require("./assets/logo.png")}></img>
            <span className="nav-toggle" onClick={() => navlist.current.classList.toggle("open")}>&#9776;</span>
            <div ref={navlist} id="h-nav-items">
               <a href="#h-about">About Us</a>
               <a href="#h-work">Our Work</a>
               <a href="#esg">ESG</a>
               <a href="#startups">Startups</a>
               <a href="#accelerators">Accelerators</a>
               <a href="#investors">Investors</a>
               <a href = "/blog">Blog</a>
            <div className="dropdown">
               <button className="btn" style={{cursor: "pointer"}}><a href="#partners" id="partner-link">Partners</a></button>
               <div className="dropdown-menu">
                  <a href="#stanford-sgi" className="dd-items">Stanford Gendered Innovation</a>
                  <a href="#landecker" className="dd-items">The Landecker Foundation</a>
                  <a href="#vinnova" className="dd-items">Vinnova</a>
               </div>
            </div>

               
               


               <Link to="/dashboard">Take the Assessment</Link>
            </div>
         
         </nav>

         <div id="h-hero">
            <div id="h-hero-text-cont">
               <h1 id="h-hero-heading">
                  <img id="h-hero-logo" src={require("./assets/icon.png")}></img>
                  Turtle Tank


               </h1>
               <div id="h-hero-subheading">
                  Guiding Startups to Sustainable Success with Regenerative Entrepreneurship
               </div>

               <span id="h-hero-line"></span>

               <img id="h-hero-earth" src={require("./assets/earth.png")}></img>
            </div>
         </div>
         <span id="hero-spacer"></span>

         <div id="h-about" className="h-text">

            <h1>About Us</h1>
            <p>
            Starting up a venture is a tall task for just about anyone, and as a company is formed and grows, many founders find they aren’t able to incorporate ESG and sustainable policies into their startups early on. However, in the changing social, political, and environmental climate of today, sustainability and equity practices are becoming increasingly important in the long run. To help combat this, we have created Turtle Tank, a gamified assessment tool to evaluate startups’ positions in sustainable development, and optimize their track in the future.
            </p>
            <br />
            <br />
            <br />

            <h1>Overview</h1>
            <p>
               Sustainability and equity practices are crucial for any organization, and often startups lack the resources and guidance to effectively implement them. Turtle Tank serves as a tool for these startups to enhance their knowledge about how to apply sustainable and equitable practices to their startups.  Developed by Stanford University’s Gendered Innovations Lab, Turtle Tank offers a comprehensive set of resources tailored to specific equity and sustainability needs.
            </p>
         </div>
         <div className="h-card" id="h-graph">
            <h1>Why?</h1>
            <ImageCycle images={[
               require("./assets/graph (1).jpeg"),
               require("./assets/graph (2).jpeg"),
               require("./assets/graph.png")
            ]} />
            <div id="h-graph-text">

               <p>These three impactful graphs displaying the concerning trends of increasing CO2 emissions, rising temperatures, and escalating pollution levels, that emphasize the critical importance for startups to assess their sustainability and implement proactive measures. By assessing their startup, we help startups evaluate their environmental impact and help them create an action plan to enhance their sustainability practices. Together, we can combat climate change, reduce carbon footprints, and create a more sustainable future for all.
               </p>
            </div>
         </div>


         <div id="h-work" className="h-text h-content">
            <div className="h-content-text">
               <h1>Regenerative Startups Assessment Framework (RSAF)</h1>
               <p>
               Our novel framework proposes a regenerative entrepreneurship model in order to build startups sustainably from the ground up. We've analyzed multiple existing frameworks, like the UN Sustainable Development Goals, the 16 Sustainability pillars of the World Economic Forum, the B-Impact tool, and more. Our novel framework synthesizes seven startup pillars with seven sustainability pillars to create conscious startups at the intersection.
               <br />
               <br />
               <br />
               Our framework has been stylized into an assessment built for early-stage startups. The aim is to integrate Enviro-Social Governance policy into startups that can then grow sustainably, ushering in a new era of businesses that operate with thought put into pressing world issues.
               </p>
            </div>
            <img className="h-img" src={require("./assets/RSAF.PNG")}></img>
         </div>

         {/* <div className="h-card"> */}
         <div id="esg" className="h-text h-content">
            <img className="h-img" src={require("./assets/esg.png")}></img>
            <div className="h-content-text">
               <h1>Importance of ESG</h1>
               <p>
                  Environmental, social, and governance factors play a vital role in the success and sustainability of companies in today's world. <br></br><br></br>Organizations that prioritize ESG considerations demonstrate their commitment to responsible and ethical business practices, while also managing risks and creating long-term value.
                  <br></br><br></br>With a focus placed on this, companies can foster positive relationships with stakeholders, attract socially conscious investors, and enhance their overall reputation.
               </p>
            </div>
         </div>
         {/* </div> */}

         <div id="startups" className="h-text h-content">
            <div className="h-content-text">
               <h1>Benefits for Startups</h1>
               <p>
                  Turtle Tank empowers startups to enhance their sustainability practices, build a strong network, and position themselves as leaders in responsible and ethical business. <br></br><br></br>Startups will receive mentoring from experienced professors and affiliates who guide them in implementing sustainability best practices. <br></br><br></br>They will gain access to gamified assessments that provide valuable insights into their current equity and sustainability practices. They will also gain a wealth of online resources and learning materials to utilize for ESG implementation.
               </p>
            </div>
            <img className="h-img" src={require("./assets/startups.png")}></img>

         </div>


         <div id="accelerators" className="h-text h-content">
            <img className="h-img" src={require("./assets/investors.png")}></img>

            <div className="h-content-text">
               <h1>Benefits for Startup Accelerators</h1>
               <p>
               By incorporating Turtle Tank's ESG startup metrics into their evaluation process,, accelerators can make decisions about which startups to invest in or support based on a new scale of evaluation that takes into account a startup's adherence to sustainable practices. This allows for more impact-minded accelerators to gain a deeper understanding of where these sustainable practices are in the startup and to focus on various areas of improvement that can help a startup become more sustainable and responsible. 
               <br />
               <br />
               <br />
               Turtle Tank’s certification also allows accelerators to quickly identify startups that are already adhering to ESG guidelines and have the potential to become leaders in sustainable and responsible business practices.
               </p>
            </div>
         </div>


         <div id="partners" className="h-text h-content">
            <div className="h-content-text">
               <h1>Our Partners <br /> <br /> <h2 id="stanford-sgi" style={{fontSize: "25px"}}>Stanford Gendered Innovation</h2></h1>
               <p>
               The Gendered Innovations project at Stanford University is a pioneering research center dedicated to promoting gender equality and inclusiveness in STEM fields. Led by a diverse team of interdisciplinary scholars, the lab conducts cutting-edge research to uncover and address gender biases and social disparities in innovation and technology. Through their rigorous analysis, the lab develops practical strategies and tools for integrating gender perspectives into research, product design, and policy-making processes, ultimately striving to create a more equitable and inclusive society.
               </p>
            </div>
            <a href="https://genderedinnovations.stanford.edu/" target="_blank"><img className="h-img" src={require("./assets/gendered.jpg")} style={{width: "400px"}} /></a>

         </div>



         <div id="landecker" className="h-text h-content">
            <a href="https://www.alfredlandecker.org/en" target="blank_"><img className="h-img" src={require("./assets/landecker.png")}></img></a>

            <div className="h-content-text">
               <h1>The Landecker Foundation</h1>
               <p>
               The Alfred Landecker Foundation is a philanthropic organization committed to addressing and tackling some of the most pressing challenges of our time. With a focus on social justice, human rights, and environmental sustainability, the foundation supports and collaborates with a diverse range of individuals, organizations, and initiatives that are dedicated to creating positive change in these areas. Through funding, research, advocacy, and partnerships, the Landecker Foundation seeks to empower individuals and communities, foster innovation, and drive systemic transformation to build a more equitable and sustainable future for all.
               </p>
            </div>
         </div>


         <div id="vinnova" className="h-text h-content">
            <div className="h-content-text">
               <h1>Vinnova</h1>
               <p>
               Vinnova, known as Sweden's innovation agency, plays a vital role in promoting and funding innovation activities within the country. Established in 2001, Vinnova operates with the Swedish Ministry of Enterprise and Innovation. The agency's primary mission is to strengthen Sweden's innovation capabilities by supporting research and development projects, fostering collaborations between academia, industry, and society, and driving the growth of innovative startups and enterprises. Vinnova aims to create a sustainable and prosperous future by encouraging the development and implementation of groundbreaking ideas, technologies, and solutions across various sectors, including healthcare, sustainable energy, digitalization, and transport. Through its funding programs, expertise, and strategic initiatives, Vinnova acts as a catalyst for innovation, driving Sweden's position as a global leader in research, development, and entrepreneurship.
               </p>
            </div>
            <a href="https://www.vinnova.se/en/p/growth-accelerator/" target="_blank"><img className="h-img" src={require("./assets/vinnova.png")} style = {{width: "400px"}}></img></a>

         </div>




         <div id="investors" className="h-text h-content">
            <img className="h-img" src={require("./assets/investors.png")}></img>

            <div className="h-content-text">
               <h1>Benefits for Investors</h1>
               <p>
                  By engaging with startups that have participated in this program, investors gain access to a pool of companies that have demonstrated their commitment to sustainability and equity. <br></br><br></br>Turtle Tank’s “vetting” provides investors with a higher level of confidence in the startups' potential for long-term success and their alignment with ESG factors. <br></br><br></br>By investing in Turtle Tank-backed startups, investors can contribute to positive social and environmental change while potentially enjoying financial returns.
               </p>
            </div>
         </div>
         <div id="h-footer">
            <h3>Turtle Tank © 2023</h3>
         </div>
      </div>)
}
