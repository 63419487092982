import React, {useState} from "react";
export function ScrollButton(){
    const [visible, setVisible] = useState(false);
    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if(scrolled > 200){
            setVisible(true)
        } else{
            setVisible(false)
        }
    }

    const scrollTop = () => {
        window.scrollTo({
            top:0,
            behaviour:'smooth'
        })
    }

    window.addEventListener('scroll', toggleVisible)
    const scrollStyle = {
        position: 'fixed',
        right: '10px', 
        bottom: '5px', 
        fontSize: '2.5rem',
        zIndex: 99,
        cursor: 'pointer', 
        color: 'cornflowerblue',
        display: visible?'inline': 'none'
    }
    return (
        <div style={scrollStyle} onClick={scrollTop}>
            <i className="fa-solid fa-circle-up"></i>
        </div>
    )
}