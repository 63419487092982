import { useEffect, useState } from "react";
import { currentUser } from "../services/auth";
import { currentStartup, setStartup,updateStartupInfo } from "../services/startup"
import "../styles/profile.css"
import { Edit } from "./Edit";

export function Profile() {
   const startup = currentStartup;

   const [name, setName] = useState(startup.name || "enter name");
   const [desc, setDesc] = useState(startup.desc || "enter description");
   const [phone, setPhone] = useState(startup.coFounderPhone || "enter phone");
   const [loc, setLocation] = useState(startup.location || "enter address");
   const [site, setWebsite] = useState(startup.website || "enter site");


   useEffect(() => {
      setStartup(name, desc, loc, startup.coFounderName, startup.coFounderEmail, phone, site);
    }, [name, desc, loc, phone, site]);
   
    useEffect(() => {
      updateStartupInfo({
        location: loc || "",
        coFounderName: startup.coFounderName || "", 
        coFounderEmail: startup.coFounderEmail || "", 
        coFounderPhone: phone || "",
        website: site || "",
      });
   }, [loc, phone, site, startup.coFounderName, startup.coFounderEmail]);

   async function handleLink() {
      try {
         await navigator.clipboard.writeText(window.location.origin + "/dashboard/profile/" + currentUser.uid)
         alert("Link copied: " + window.location.origin + "/dashboard/profile/" + currentUser.uid)
      } catch (error) {
         alert("Error copying link, copy it on your own: " + window.location.origin + "/dashboard/profile/" + currentUser.uid)
         
      }
   }
   return (
      <>
      <h1 style={{ color: "var(--theme-accent)" }}>Profile</h1>

      <div className="profile">
        <div className="about">
          <Edit value={name} onSave={setName} type="heading" />
          <Edit value={desc} onSave={setDesc} type="multiline" />
          <Edit value={loc} onSave={setLocation} type="multiline" />
          <Edit value={phone} onSave={setPhone} type="multiline" />
          <Edit value={site} onSave={setWebsite} type="multiline" />
          <button className="turtle-button" onClick={handleLink}>
            Share
          </button>
        </div>
        <div className="score-cont">
          {startup.timeline.length === 0 ? (
            <div className="score-cont">
              <span className="scores">No Assessments!</span>
            </div>
          ) : (
            <div className="score-cont">
              <span className="scores">
                Latest Score: {startup.timeline.sort((a, b) => b.date.seconds - a.date.seconds)[0].score}
              </span>
              <span className="scores">
                Highest Score: {startup.timeline.sort((a, b) => b.score - a.score)[0].score}
              </span>
            </div>
          )}
        </div>
      </div>
    </>
   )
}
