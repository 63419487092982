import { useNavigate, useParams } from "react-router-dom"
import { getAssessment, getChallengerCards, getTurtleCards } from "../../services/assessment";
import { challengerFirstID, challengerLength } from "../../services/Data";

export function ViewAssessment() {
   const { id } = useParams();
   const assessment = getAssessment(id);
   const navigate = useNavigate()
   function getCard(id) {
      if (id < challengerFirstID) {
         return getTurtleCards().find(x => x.id == id).cards[1].content;
      } else {
         return getChallengerCards().find(x => x.id == id).card;
      }
   }
   return <>
      <div className="assessment" >
         <button className="turtle-button" onClick={() => navigate("/dashboard")}>Back to dashboard</button>
         <h3>Score - {assessment.score}, {assessment.date.toDate().toLocaleString()}</h3>
         <div className="meta-box">
            <div>
               <h5>Number of Turtle Cards chosen</h5>
               <h1>{assessment.turtle_cards.filter(x => x.status == 0).length}</h1>
            </div>
            <div>
               <h5>Number of Turtle Cards set for To-do</h5>
               <h1>{assessment.turtle_cards.filter(x => x.status == 1).length}</h1>
            </div>
            <div>
               <h5>Number of Turtle Cards rejected</h5>
               <h1> {assessment.turtle_cards.filter(x => x.status == 2).length}</h1>
            </div>
            <div>
               <h5>Number of Challenger Cards Cards chosen</h5>
               <h1>{assessment.challenger_cards.filter(x => x.status == 0).length}</h1>
            </div>
            <div>
               <h5>Number of Challenger Cards set for To-do</h5>
               <h1>{assessment.challenger_cards.filter(x => x.status == 1).length}</h1>
            </div>
            <div>
               <h5> Number of Challenger Cards rejected</h5>
               <h1>{challengerLength + 1 - (assessment.challenger_cards.filter(x => x.status == 0).length + assessment.challenger_cards.filter(x => x.status == 1).length)}</h1>
            </div>
         </div>
         <h2 style={{ textAlign: "center" }}>Action Plan</h2>
         <div className="plan-card">
            <h5>Introduction</h5>
            <p>{assessment.action_plan.find(x => x.id == "intro").content}</p>
         </div>

         {
            assessment.action_plan.filter(x => x.id != "intro").map((x, i) =>
               <div className="plan-card" key={i}>
                  <h5>{getCard(x.id)}</h5>
                  <p>{x.content}</p>
               </div>
            )
         }
      </div>
   </>
}