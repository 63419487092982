export function Card({bank, title,description,onClick,score, id}){

   
   return (
      
      
      <div className={`card card-${score}`} onClick={onClick}>
         {/* <h2 className="card-title">{title}</h2> */}
         <div className="card-description">{description}</div>
      </div>


   );
}
      //    function myFunction() {
      //       var x = document.createElement("HEADER");
      //       x.setAttribute("id", "myHeader");
      //       document.body.appendChild(x);
   
            
      //       if ({id} === 0 || {id} === 1){
      //          var zero = document.createElement("H1"); 
      //          var one = document.createTextNode("Round 1");
      //          one.innerHTML = "Round 1";
      //          one.style.color = "rgb(0,0,255)";
      //          zero.appendChild(one);
      //          document.getElementById("myHeader").appendChild(zero);
   
      //       }
            
   
      //       if ({id} === 2 || {id} === 3 || {id} === 4){
      //          var two = document.createElement("H1"); 
      //          var three = document.createTextNode("Round 2");
      //          three.innerHTML = "Round 2";
      //          three.style.color = "rgb(0,0,255)";
      //          two.appendChild(three);
      //          document.getElementById("myHeader").appendChild(two);
      //       }
            
   
      //       if ({id} === 5 || {id} === 6 || {id} === 7){
      //          var five = document.createElement("H1"); 
      //          var six = document.createTextNode("Round 3");
      //          six.innerHTML = "Round 3";
      //          six.style.color = "rgb(0,0,255)";
      //          five.appendChild(six);
      //          document.getElementById("myHeader").appendChild(five);
      //       }
           
   
      //       if ({id} === 8 || {id} === 9){
      //          var eight = document.createElement("H1"); 
      //          var nine = document.createTextNode("Round 4");
      //          nine.innerHTML = "Round 4";
      //          nine.style.color = "rgb(0,0,255)";
      //          eight.appendChild(nine);
      //          document.getElementById("myHeader").appendChild(eight);
      //       }
            
   
      //       if ({id} === 10 || {id} === 11 || {id} === 12){
      //          var ten = document.createElement("H1"); 
      //          var eleven = document.createTextNode("Round 5");
      //          eleven.innerHTML = "Round 5";
      //          eleven.style.color = "rgb(0,0,255)";
      //          ten.appendChild(eleven);
      //          document.getElementById("myHeader").appendChild(ten);
      //       }
            
   
      //       if ({id} === 13 || {id} === 14){
      //          var thirteen = document.createElement("H1"); 
      //          var fourteen = document.createTextNode("Round 6");
      //          fourteen.innerHTML = "Round 6";
      //          fourteen.style.color = "rgb(0,0,255)";
      //          thirteen.appendChild(fourteen);
      //          document.getElementById("myHeader").appendChild(thirteen);
      //       }
            
   
      //       if ({id} === 15 || {id} === 16){
      //          var fifteen = document.createElement("H1"); 
      //          var sixteen = document.createTextNode("Round 7");
      //          sixteen.innerHTML = "Round 7";
      //          sixteen.style.color = "rgb(0,0,255)";
      //          fifteen.appendChild(sixteen);
      //          document.getElementById("myHeader").appendChild(zero);
      //       }
            
   
      // },