import React, { useEffect } from 'react'
import { QUERY_ONE_POST } from '../Graphql/Queries';
import { Post } from './Post';
import '../styles/blog.css'
import { Comments } from './Comments';
import { useQueryPosts } from '../hooks/useQueryPosts';
import { Loading } from './Loading';
import { useMyContext } from './store';
import { ScrollButton } from './ScrollButton';

export function Article(){
    const {loading} = useMyContext();
    const {posts, error } = useQueryPosts({query: QUERY_ONE_POST});
    if(error) return <h2 style={{textAlign: 'center'}}>{error}</h2>
    

    return(
        <>
        {loading && <Loading/>}
        <div className='post-container'>
            {
                posts?.map(post => (
                    <React.Fragment key = {post.id}> 
                        <Post post={post}/>
                        <Comments comments={post?.comments}/>
                        <ScrollButton/>
                    </React.Fragment>
                ))
            }
        </div>
        </> 
    )
}