import { useParams } from "react-router-dom"
import "../styles/profile.css"
import { getStartup } from "../services/startup"
import { useEffect, useState } from "react"

export function StartupProfile() {
   const { uid } = useParams()
   const [startup, setStartup] = useState(false);
   useEffect(() => {
      (async () => setStartup(await getStartup(uid)))();
   }, []);
   if (startup == null) return <div>Startup not found</div>
   if (!startup) return <div>Loading...</div>
   return (<>
      <h1 style={{ color: "var(--theme-accent)" }}>Profile</h1>

      <div className="profile">

      <div className="about">
            <h2>{startup.name}</h2>
            <p>{startup.desc}</p>
            <p>{startup.coFounderPhone}</p>
            <p>{startup.location}</p>
            <p>{startup.website}</p>
         </div>

         <div className="score-cont">
          {startup.timeline.length === 0 ? (
            <div className="score-cont">
              <span className="scores">No Assessments!</span>
            </div>
          ) : (
            <div className="score-cont">
              <span className="scores">
                Latest Score: {startup.timeline.sort((a, b) => b.date.seconds - a.date.seconds)[0].score}
              </span>
              <span className="scores">
                Highest Score: {startup.timeline.sort((a, b) => b.score - a.score)[0].score}
              </span>
            </div>
          )}
        </div>
         
         
         
      </div>
   </>
   )
}