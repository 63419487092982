import React, { useEffect } from 'react'
import { useParams } from 'react-router'
import Posts from './Posts';
import { graphcms, QUERY_SEARCH_POSTS } from '../Graphql/Queries';
import { useState } from 'react';

export function Post({ post }) {
    return (
        <article>

            <div className="title-and-time">
                <h2 className='title'>{post.title}</h2>
                <div className='published-time'>
                    <i className='far fa-clock'></i>
                    <span>{new Date(post.updatedAt).toDateString()} </span>
                </div>
            </div>
            <img className="cover-image" src={post.coverPhoto.url}></img>




            <div className='content' dangerouslySetInnerHTML={{ __html: post.content.html }}></div>
        </article>
    );
}