import { useEffect} from "react";
import * as startup from "../services/startup.js"

export function StartupCreate({ show, onceDone }) {
   if (!show) return <></>;

   function handleSubmit(e) {
      e.preventDefault();
      const name = e.target.startupName.value;
      const desc = e.target.startupDesc.value;
      const location = e.target.startupLocation.value || ""; 
      const coFounderName = e.target.cofounderName.value || ""; 
      const coFounderEmail = e.target.cofounderEmail.value || ""; 
      const coFounderPhone = e.target.cofounderPhone.value || ""; 
      const website = e.target.startupWebsite.value || ""; 

      startup.setStartup(name, desc, location, coFounderName, coFounderEmail, coFounderPhone, website).then(onceDone);
   }



   return (
   <div className="createStartUp">

   <form onSubmit={handleSubmit}>
      <div className="scrollable-form">
      <h1>Create Startup</h1>

         <div className="form-input">
            <label htmlFor="startupName">Startup Name</label><br/>
            <input placeholder="My Awesome Sustainable Startup" type="text" name="startupName" />
         </div>
         <div className="form-input">
            <label htmlFor="startupDesc">Startup Description</label><br/>
            <textarea placeholder="We work on giving a smile to everyone." name="startupDesc"></textarea>
         </div>
         <div className="form-input">
            <label htmlFor="startupLocation">Startup Location</label><br/>
            <input placeholder="New York, USA" type="text" name="startupLocation" />
         </div>
         <div className="form-input">
            <label htmlFor="cofounderName">Cofounder Name</label><br/>
            <input placeholder="John Doe" type="text" name="cofounderName" />
         </div>
         <div className="form-input">
            <label htmlFor="cofounderEmail">Cofounder Email</label><br/>
            <input placeholder="john@example.com" type="email" name="cofounderEmail" />
         </div>
         <div className="form-input">
            <label htmlFor="cofounderPhone">Cofounder Phone</label><br/>
            <input placeholder="123-456-7890" type="tel" name="cofounderPhone" />
         </div>
         <div className="form-input">
            <label htmlFor="startupWebsite">Startup Website</label><br/>
            <input placeholder="https://www.myawesomestartup.com" type="url" name="startupWebsite" />
         </div>
      </div>
      <input className="turtle-button" type="submit" value="Submit" />

   </form>
   </div>



   )
}