

export var turtleSets = [

   
   {
       
       "title": "Purpose & Vision",
       "id": 0,
       "bank": [
        {
            "DEI": "DEI: Diversity, Equity and Inclusion",
            "Intersectional Analysis": "Intersectional Analysis: An analysis based off of the framework that one should be aware of people's multiple identities (race, gender, age, etc.)",
            "Circular Economy": "Circular Economy: An economic system focused on low throughput and waste reuse",
            "Product life cycle": "Product life cycle: The resources and energy a product throughout its life",
            "GHG": "GHG: Greenhouse gas",
            "Sustainability": "Sustainability: The ability of humanity to coexist with the biosphere",
            "Green transportation": "Green transportation: Transportation that does not burden the biosphere",
            "Responsible Innovation Frameworks": "Responsible Innovation Frameworks: Frameworks that emphasize innovation done with emphasis on Enviro-Social Governance",


        }
       ],
       "cards": [
           {
               "content": "Our purpose / vision / mission / values statements explicitly include social / environmental /societal health impacts that we aim to achieve",
               "score": 10
           },
           {
               "content": "We will rework our Vision / mission / values statements to include social / environmental /societal health impacts that we aim to achieve",
               "score": 5
           },
           {
               "content": "We do not want to alter our vision/ mission /values statements for now",
               "score": 0
           }
       ],
       
   },
   {
       
       "title": "Purpose & Vision",
       "bank": [
        {
            "DEI": "DEI: Diversity, Equity and Inclusion",
            "IA": "Intersectional Analysis: An analysis based off of the framework that one should be aware of people's multiple identities (race, gender, age, etc.)",
            "CE": "Circular Economy: An economic system focused on low throughput and waste reuse",
            "PLC": "Product life cycle: The resources and energy a product throughout its life",
            "GHG": "GHG: Greenhouse gas",
            "Sustainability": "Sustainability: The ability of humanity to coexist with the biosphere",
            "GT": "Green transportation: Transportation that does not burden the biosphere",
            "RIF": "Responsible Innovation Frameworks: Frameworks that emphasize innovation done with emphasis on Enviro-Social Governance",


        }
       ],
       "cards": [
           {
               "content": "We share/communicate our sustainability vision/ mission on a regular basis with all our stakeholders",
               "score": 10
           },
           {
               "content": "We will set up a process /system immediately to share our sustainability vision /mission on a regular basis with all our stakeholders",
               "score": 5
           },
           {
               "content": "We cannot prioritize this process/system building at this point. We will consider this later.",
               "score": 0
           }
       ],
       "id": 1
   },
   {
    
       "title": "Leadership, Team & Partnerships",
       "bank": [
        {
            "DEI": "DEI: Diversity, Equity and Inclusion",
            "Intersectional Analysis": "Intersectional Analysis: An analysis based off of the framework that one should be aware of people's multiple identities (race, gender, age, etc.)",
            "Circular Economy": "Circular Economy: An economic system focused on low throughput and waste reuse",
            "Product life cycle": "Product life cycle: The resources and energy a product throughout its life",
            "GHG": "GHG: Greenhouse gas",
            "Sustainability": "Sustainability: The ability of humanity to coexist with the biosphere",
            "Green transportation": "Green transportation: Transportation that does not burden the biosphere",
            "Responsible Innovation Frameworks": "Responsible Innovation Frameworks: Frameworks that emphasize innovation done with emphasis on Enviro-Social Governance",


        }
       ],
       "cards": [
           {
               "content": "We have a diverse representation in our founding team",
               "score": 10
           },
           {
               "content": "We will onboard a diverse representation in our founding team within the next 3 months",
               "score": 5
           },
           {
               "content": "We do not want to change the founding team structure",
               "score": 0
           }
       ],
       "id": 2
   },
   {
   
       "title": "Leadership, Team & Partnerships",
       "bank": [
        {
            "DEI": "DEI: Diversity, Equity and Inclusion",
            "Intersectional Analysis": "Intersectional Analysis: An analysis based off of the framework that one should be aware of people's multiple identities (race, gender, age, etc.)",
            "Circular Economy": "Circular Economy: An economic system focused on low throughput and waste reuse",
            "Product life cycle": "Product life cycle: The resources and energy a product throughout its life",
            "GHG": "GHG: Greenhouse gas",
            "Sustainability": "Sustainability: The ability of humanity to coexist with the biosphere",
            "Green transportation": "Green transportation: Transportation that does not burden the biosphere",
            "Responsible Innovation Frameworks": "Responsible Innovation Frameworks: Frameworks that emphasize innovation done with emphasis on Enviro-Social Governance",


        }
       ],
       "cards": [
           {
               "content": "We provide employment opportunities for equity-deserving populations.",
               "score": 10
           },
           {
               "content": "We will start providing employment Opportunities for equity-deserving populations",
               "score": 5
           },
           {
               "content": "We can consider equity-based hiring only at a later stage",
               "score": 0
           }
       ],
       "id": 3
   },
   {
    
       "title": "Leadership, Team & Partnerships",
       "bank": [
        {
            "DEI": "DEI: Diversity, Equity and Inclusion",
            "Intersectional Analysis": "Intersectional Analysis: An analysis based off of the framework that one should be aware of people's multiple identities (race, gender, age, etc.)",
            "Circular Economy": "Circular Economy: An economic system focused on low throughput and waste reuse",
            "Product life cycle": "Product life cycle: The resources and energy a product throughout its life",
            "GHG": "GHG: Greenhouse gas",
            "Sustainability": "Sustainability: The ability of humanity to coexist with the biosphere",
            "Green transportation": "Green transportation: Transportation that does not burden the biosphere",
            "Responsible Innovation Frameworks": "Responsible Innovation Frameworks: Frameworks that emphasize innovation done with emphasis on Enviro-Social Governance",


        }
       ],
       "cards": [
           {
               "content": "We have a published DEI policy",
               "score": 10
           },
           {
               "content": "We will publish a DEI policy within the next 1 month",
               "score": 5
           },
           {
               "content": "We do not prioritize DEI at this stage and probably consider this later",
               "score": 0
           }
       ],
       "id": 4
   },
  
   {
    
       "title": "Innovation & Design Processes",
       "bank": [
        {
            "DEI": "DEI: Diversity, Equity and Inclusion",
            "Intersectional Analysis": "Intersectional Analysis: An analysis based off of the framework that one should be aware of people's multiple identities (race, gender, age, etc.)",
            "Circular Economy": "Circular Economy: An economic system focused on low throughput and waste reuse",
            "Product life cycle": "Product life cycle: The resources and energy a product throughout its life",
            "GHG": "GHG: Greenhouse gas",
            "Sustainability": "Sustainability: The ability of humanity to coexist with the biosphere",
            "Green transportation": "Green transportation: Transportation that does not burden the biosphere",
            "Responsible Innovation Frameworks": "Responsible Innovation Frameworks: Frameworks that emphasize innovation done with emphasis on Enviro-Social Governance",


        }
       ],
       "cards": [
           {
               "content": "We have used /use intersectional design methods in our new product / service development",
               "score": 10
           },
           {
               "content": "We will conduct intersectional analysis methods in our new product /service development",
               "score": 5
           },
           {
               "content": "We have already completed product/service design and not keen on bringing changes now",
               "score": 0
           }
       ],
       "id": 5
   },
   {
    
       "title": "Innovation & Design Processes",
       "bank": [
        {
            "DEI": "DEI: Diversity, Equity and Inclusion",
            "Intersectional Analysis": "Intersectional Analysis: An analysis based off of the framework that one should be aware of people's multiple identities (race, gender, age, etc.)",
            "Circular Economy": "Circular Economy: An economic system focused on low throughput and waste reuse",
            "Product life cycle": "Product life cycle: The resources and energy a product throughout its life",
            "GHG": "GHG: Greenhouse gas",
            "Sustainability": "Sustainability: The ability of humanity to coexist with the biosphere",
            "Green transportation": "Green transportation: Transportation that does not burden the biosphere",
            "Responsible Innovation Frameworks": "Responsible Innovation Frameworks: Frameworks that emphasize innovation done with emphasis on Enviro-Social Governance",


        }
       ],
       "cards": [
           {
               "content": "We have a diverse team in our design processes and consider their perspectives before finalizing the design",
               "score": 10
           },
           {
               "content": "We will incorporate diverse representation in our design teams in the near future",
               "score": 5
           },
           {
               "content": "Our design team is already set and have no scope in incorporating a diverse team",
               "score": 0
           }
       ],
       "id": 6
   },
   {
    
       "title": "Innovation & Design Processes",
       "bank": [
        {
            "DEI": "DEI: Diversity, Equity and Inclusion",
            "Intersectional Analysis": "Intersectional Analysis: An analysis based off of the framework that one should be aware of people's multiple identities (race, gender, age, etc.)",
            "Circular Economy": "Circular Economy: An economic system focused on low throughput and waste reuse",
            "Product life cycle": "Product life cycle: The resources and energy a product throughout its life",
            "GHG": "GHG: Greenhouse gas",
            "Sustainability": "Sustainability: The ability of humanity to coexist with the biosphere",
            "Green transportation": "Green transportation: Transportation that does not burden the biosphere",
            "Responsible Innovation Frameworks": "Responsible Innovation Frameworks: Frameworks that emphasize innovation done with emphasis on Enviro-Social Governance",


        }
       ],
       "cards": [
           {
               "content": "We regularly conduct thorough review of product design for any unethical and unsustainable outcomes due to the use",
               "score": 10
           },
           {
               "content": "We will conduct thorough review of product design for any unethical and unsustainable outcomes due to the use",
               "score": 5
           },
           {
               "content": "We are not aware of the unethical / unsustainable outcomes of our product",
               "score": 0
           }
       ],
       "id": 7
   },
   
   {
   
       "title": "Business & Revenue Models",
       "bank": [
        {
            "DEI": "DEI: Diversity, Equity and Inclusion",
            "Intersectional Analysis": "Intersectional Analysis: An analysis based off of the framework that one should be aware of people's multiple identities (race, gender, age, etc.)",
            "Circular Economy": "Circular Economy: An economic system focused on low throughput and waste reuse",
            "Product life cycle": "Product life cycle: The resources and energy a product throughout its life",
            "GHG": "GHG: Greenhouse gas",
            "Sustainability": "Sustainability: The ability of humanity to coexist with the biosphere",
            "Green transportation": "Green transportation: Transportation that does not burden the biosphere",
            "Responsible Innovation Frameworks": "Responsible Innovation Frameworks: Frameworks that emphasize innovation done with emphasis on Enviro-Social Governance",


        }
       ],
       "cards": [
           {
               "content": "We are sure that our Business Model does not involve any direct harm to the society or the environment.",
               "score": 10
           },
           {
               "content": "We plan to mitigate the direct harm to the society or the environment by taking necessary actions",
               "score": 5
           },
           {
               "content": "We cannot change our business model for any reason.",
               "score": 0
           }
       ],
       "id": 8
   },
   {
  
       "title": "Business & Revenue Models",
       "bank": [
        {
            "DEI": "DEI: Diversity, Equity and Inclusion",
            "Intersectional Analysis": "Intersectional Analysis: An analysis based off of the framework that one should be aware of people's multiple identities (race, gender, age, etc.)",
            "Circular Economy": "Circular Economy: An economic system focused on low throughput and waste reuse",
            "Product life cycle": "Product life cycle: The resources and energy a product throughout its life",
            "GHG": "GHG: Greenhouse gas",
            "Sustainability": "Sustainability: The ability of humanity to coexist with the biosphere",
            "Green transportation": "Green transportation: Transportation that does not burden the biosphere",
            "Responsible Innovation Frameworks": "Responsible Innovation Frameworks: Frameworks that emphasize innovation done with emphasis on Enviro-Social Governance",


        }
       ],
       "cards": [
           {
               "content": "Our Business / Revenue Model is not predatory or leads to loss of other businesses /livelihoods and allows sufficient market competition.",
               "score": 10
           },
           {
               "content": "We are aware of the potential predatory practices and loss of other businesses / livelihoods and aim to mitigate this through our ethical practices.",
               "score": 5
           },
           {
               "content": "We are unaware of potential harms caused by our business/revenue models and do not know how to mitigate them",
               "score": 0
           }
       ],
       "id": 9
   },
   
   {
    
       "title": "Operations & Measuring Impact",
       "bank": [
        {
            "DEI": "DEI: Diversity, Equity and Inclusion",
            "Intersectional Analysis": "Intersectional Analysis: An analysis based off of the framework that one should be aware of people's multiple identities (race, gender, age, etc.)",
            "Circular Economy": "Circular Economy: An economic system focused on low throughput and waste reuse",
            "Product life cycle": "Product life cycle: The resources and energy a product throughout its life",
            "GHG": "GHG: Greenhouse gas",
            "Sustainability": "Sustainability: The ability of humanity to coexist with the biosphere",
            "Green transportation": "Green transportation: Transportation that does not burden the biosphere",
            "Responsible Innovation Frameworks": "Responsible Innovation Frameworks: Frameworks that emphasize innovation done with emphasis on Enviro-Social Governance",


        }
       ],
       "cards": [
           {
               "content": "We report the Environmental impacts of our business operations with the help of certifications (For eg: Environmental cerifications like Green C, B-Corp  etc)",
               "score": 10
           },
           {
               "content": "We plan to include measuring of our environmental impacts by taking up necessary certifications within the next one year",
               "score": 5
           },
           {
               "content": "We do not wish to have any environmental certifications at this stage of our startup",
               "score": 0
           }
       ],
       "id": 10
   },
   {
    
       "title": "Operations & Measuring Impact",
       "bank": [
        {
            "DEI": "DEI: Diversity, Equity and Inclusion",
            "Intersectional Analysis": "Intersectional Analysis: An analysis based off of the framework that one should be aware of people's multiple identities (race, gender, age, etc.)",
            "Circular Economy": "Circular Economy: An economic system focused on low throughput and waste reuse",
            "Product life cycle": "Product life cycle: The resources and energy a product throughout its life",
            "GHG": "GHG: Greenhouse gas",
            "Sustainability": "Sustainability: The ability of humanity to coexist with the biosphere",
            "Green transportation": "Green transportation: Transportation that does not burden the biosphere",
            "Responsible Innovation Frameworks": "Responsible Innovation Frameworks: Frameworks that emphasize innovation done with emphasis on Enviro-Social Governance",


        }
       ],
       "cards": [
           {
               "content": "We have energy efficient building / infrastructure and conduct regular energy audits",
               "score": 10
           },
           {
               "content": "We will ensure that all our office premises follow energy efficient methods and will conduct regular energy audits",
               "score": 5
           },
           {
               "content": "We do not have any direct control on the energy efficiency of the buildings that we operate from",
               "score": 0
           }
       ],
       "id": 11
   },
   {
    
       "title": "Operations & Measuring Impact",
       "bank": [
        {
            "DEI": "DEI: Diversity, Equity and Inclusion",
            "Intersectional Analysis": "Intersectional Analysis: An analysis based off of the framework that one should be aware of people's multiple identities (race, gender, age, etc.)",
            "Circular Economy": "Circular Economy: An economic system focused on low throughput and waste reuse",
            "Product life cycle": "Product life cycle: The resources and energy a product throughout its life",
            "GHG": "GHG: Greenhouse gas",
            "Sustainability": "Sustainability: The ability of humanity to coexist with the biosphere",
            "Green transportation": "Green transportation: Transportation that does not burden the biosphere",
            "Responsible Innovation Frameworks": "Responsible Innovation Frameworks: Frameworks that emphasize innovation done with emphasis on Enviro-Social Governance",


        }
       ],
       "cards": [
           {
               "content": "We have included circular economy in our business operations including product packaging (by having options for reduce / reuse/recycle/repair options for our products )",
               "score": 10
           },
           {
               "content": "Circular economy factors are not directly applicable in our business operations (for eg: IT services). But we do look into our vendor/supply chain for their circularity / social / environmental processes.",
               "score": 5
           },
           {
               "content": "We cannot consider circular economy for our products or check the vendor /supply chain details at this stage of our startup.",
               "score": 0
           }
       ],
       "id": 12
   },
   
   {
    
       "title": "Governance & Ownership",
       "bank": [
        {
            "DEI": "DEI: Diversity, Equity and Inclusion",
            "Intersectional Analysis": "Intersectional Analysis: An analysis based off of the framework that one should be aware of people's multiple identities (race, gender, age, etc.)",
            "Circular Economy": "Circular Economy: An economic system focused on low throughput and waste reuse",
            "Product life cycle": "Product life cycle: The resources and energy a product throughout its life",
            "GHG": "GHG: Greenhouse gas",
            "Sustainability": "Sustainability: The ability of humanity to coexist with the biosphere",
            "Green transportation": "Green transportation: Transportation that does not burden the biosphere",
            "Responsible Innovation Frameworks": "Responsible Innovation Frameworks: Frameworks that emphasize innovation done with emphasis on Enviro-Social Governance",


        }
       ],
       "cards": [
           {
               "content": "Our governing board consists of diverse participation",
               "score": 10
           },
           {
               "content": "We will add new governing board members to have diverse participation",
               "score": 5
           },
           {
               "content": "We do not want to dismantle the current governing board members",
               "score": 0
           }
       ],
       "id": 13
   },
   {
   
       "title": "Governance & Ownership",
       "bank": [
        {
            "DEI": "DEI: Diversity, Equity and Inclusion",
            "Intersectional Analysis": "Intersectional Analysis: An analysis based off of the framework that one should be aware of people's multiple identities (race, gender, age, etc.)",
            "Circular Economy": "Circular Economy: An economic system focused on low throughput and waste reuse",
            "Product life cycle": "Product life cycle: The resources and energy a product throughout its life",
            "GHG": "GHG: Greenhouse gas",
            "Sustainability": "Sustainability: The ability of humanity to coexist with the biosphere",
            "Green transportation": "Green transportation: Transportation that does not burden the biosphere",
            "Responsible Innovation Frameworks": "Responsible Innovation Frameworks: Frameworks that emphasize innovation done with emphasis on Enviro-Social Governance",


        }
       ],
       "cards": [
           {
               "content": "All our employees are formally eligible to participate in the ownership structure of the company",
               "score": 10
           },
           {
               "content": "We might consider certain employees for ownership in the company in the future",
               "score": 5
           },
           {
               "content": "We do not want employees to be a part of the ownership in the company",
               "score": 0
           }
       ],
       "id": 14
   },
  
   {
   
       "title": "Capital & Financing",
       "bank": [
        {
            "DEI": "DEI: Diversity, Equity and Inclusion",
            "Intersectional Analysis": "Intersectional Analysis: An analysis based off of the framework that one should be aware of people's multiple identities (race, gender, age, etc.)",
            "Circular Economy": "Circular Economy: An economic system focused on low throughput and waste reuse",
            "Product life cycle": "Product life cycle: The resources and energy a product throughout its life",
            "GHG": "GHG: Greenhouse gas",
            "Sustainability": "Sustainability: The ability of humanity to coexist with the biosphere",
            "Green transportation": "Green transportation: Transportation that does not burden the biosphere",
            "Responsible Innovation Frameworks": "Responsible Innovation Frameworks: Frameworks that emphasize innovation done with emphasis on Enviro-Social Governance",


        }
       ],
       "cards": [
           {
               "content": "We ensure that all our funding sources are supporting our sustainability values",
               "score": 10
           },
           {
               "content": "We will henceforth ensure that we discuss our sustainability values with our investors before receiving investments",
               "score": 5
           },
           {
               "content": "We are not ready to discuss these with investors at this phase as funding is more critical than sustainability values",
               "score": 0
           }
       ],
       "id": 15
   },
   {
    
       "title": "Capital & Financing",
       "bank": [
        {
            "DEI": "DEI: Diversity, Equity and Inclusion",
            "Intersectional Analysis": "Intersectional Analysis: An analysis based off of the framework that one should be aware of people's multiple identities (race, gender, age, etc.)",
            "Circular Economy": "Circular Economy: An economic system focused on low throughput and waste reuse",
            "Product life cycle": "Product life cycle: The resources and energy a product throughout its life",
            "GHG": "GHG: Greenhouse gas",
            "Sustainability": "Sustainability: The ability of humanity to coexist with the biosphere",
            "Green transportation": "Green transportation: Transportation that does not burden the biosphere",
            "Responsible Innovation Frameworks": "Responsible Innovation Frameworks: Frameworks that emphasize innovation done with emphasis on Enviro-Social Governance",


        }
       ],
       "cards": [
           {
               "content": "Our fund-raising Pitch deck talks about our social / environmental /societal health impacts.",
               "score": 10
           },
           {
               "content": "We will include social / environmental/societal health impacts in our pitch deck",
               "score": 5
           },
           {
               "content": "We are not sure of including social/ environmental/societal health impacts in our pitch deck",
               "score": 0
           }
       ],
       "id": 16
   }
]

export const turtleLength = turtleSets.length;

export var challengerSets = [
   {
       "card": "We measure and record all solid waste generated in our operations and have proper waste recycling methods in place. ",
       "title": "Circular Economy",
       "directions": "Challenger Cards - sort the cards provided into the categories based on which category fits each guideline best. These will be used to draft an 'action plan' at the end of the assessment.",
       "id": 27
   },
   {
       "card": "We have implemented circular economy methods in our business model",
       "title": "Circular Economy",
       "directions": "Challenger Cards - sort the cards provided into the categories based on which category fits each guideline best. These will be used to draft an 'action plan' at the end of the assessment.",
       "id": 28
   },
   {
    
       "card": "We monitor and record our product and packaging waste. ",
       "title": "Circular Economy",
       "directions": "Challenger Cards - sort the cards provided into the categories based on which category fits each guideline best. These will be used to draft an 'action plan' at the end of the assessment.",
       "id": 29
   },
   {
       "card": "We provide easy repair options for our products.",
       "title": "Circular Economy",
       "directions": "Challenger Cards - sort the cards provided into the categories based on which category fits each guideline best. These will be used to draft an 'action plan' at the end of the assessment.",
       "id": 30
   },
   {
       "card": "We educate consumers about the potential hazards of the use of our product.",
       "title": "Circular Economy",
       "directions": "Challenger Cards - sort the cards provided into the categories based on which category fits each guideline best. These will be used to draft an 'action plan' at the end of the assessment.",
       "id": 31
   },
   {
       "card": "We conduct  Product Life cycle assessment ",
       "title": "Circular Economy",
       "directions": "Challenger Cards - sort the cards provided into the categories based on which category fits each guideline best. These will be used to draft an 'action plan' at the end of the assessment.",
       "id": 32
   },
   {
       "card": "Our products can be broken down easily into components and recycled through local waste infrastructure.",
       "title": "Circular Economy",
       "directions": "Challenger Cards - sort the cards provided into the categories based on which category fits each guideline best. These will be used to draft an 'action plan' at the end of the assessment.",
       "id": 33
   },
   {
       "card": "We design products with circularity",
       "title": "Circular Economy",
       "directions": "Challenger Cards - sort the cards provided into the categories based on which category fits each guideline best. These will be used to draft an 'action plan' at the end of the assessment.",
       "id": 34
   },
   {
       "card": "We provide refurbish/repurpose options for our products ",
       "title": "Circular Economy",
       "directions": "Challenger Cards - sort the cards provided into the categories based on which category fits each guideline best. These will be used to draft an 'action plan' at the end of the assessment.",
       "id": 35
   },
   {
       "card": "We choose vendors / suppliers who follow circularity for their products supplied to us.",
       "title": "Circular Economy",
       "directions": "Challenger Cards - sort the cards provided into the categories based on which category fits each guideline best. These will be used to draft an 'action plan' at the end of the assessment.",
       "id": 36
   },
   {
       "directions": "Challenger Cards - sort the cards provided into the categories based on which category fits each guideline best. These will be used to draft an 'action plan' at the end of the assessment.",
       "card": "Our workplace/office premises have energy efficiency ratings.",
       "title": "Clean Energy & Consumption",
       "id": 37
   },
   {
       "card": "We promote flexible and work from home options for our team in order to save energy in commuting.",
       "title": "Clean Energy & Consumption",
       "id": 38
   },
   {
       "card": "We monitor and record our operational greenhouse gas emissions, relative to emissions in a baseline reference year.",
       "title": "Clean Energy & Consumption",
       "id": 39
   },
   {
       "card": "We have entrusted agencies who monitor our GHG emissions. ",
       "title": "Clean Energy & Consumption",
       "id": 40
   },
   {
       "card": "We report our GHG emission details in public domains",
       "title": "Clean Energy & Consumption",
       "id": 41
   },
   {
       "card": "We purchase or invest in renewable energy sources for our core functions ",
       "title": "Clean Energy & Consumption",
       "id": 42
   },
   {
       "card": "We encourage our employees to use renewable energy sources in their homes by conducting awareness programs ",
       "title": "Clean Energy & Consumption",
       "id": 43
   },
   {
       "card": "We promote green transportation and encourage /support our employees to buy/ rent such modes of transportation for official/persona purposes. ",
       "title": "Clean Energy & Consumption",
       "id": 44
   },
   {
       "card": "We  insist our vendors /suppliers to use green transportation for their deliveries ",
       "title": "Clean Energy & Consumption",
       "id": 45
   },
   {
       "card": "We have implemented green transportation for our business deliveries ",
       "title": "Clean Energy & Consumption",
       "id": 46
   },
   {
       "card": "We record and measure the fossil fuel consumption for our business operations.",
       "title": "Clean Energy & Consumption",
       "id": 47
   },
   {
       "directions": "Challenger Cards - sort the cards provided into the categories based on which category fits each guideline best. These will be used to draft an 'action plan' at the end of the assessment.",
       "card": "We measure and record all air pollution caused by us and have processes to offset/mitigate it. ",
       "title": "Clean Air / Land / Water /Biodiversity",
       "id": 48
   },
   {
       "card": "We measure and record all water pollution caused by us and have processes to offset/mitigate it. ",
       "title": "Clean Air / Land / Water /Biodiversity",
       "id": 49
   },
   {
       "card": "We measure and record all land pollution caused by us and have processes to offset/mitigate it. ",
       "title": "Clean Air / Land / Water /Biodiversity",
       "id": 50
   },
   {
       "card": "We measure and record any probable nature loss/biodiversity loss and have processes to offset/ mitigate it. ",
       "title": "Clean Air / Land / Water /Biodiversity",
       "id": 51
   },
   {
       "card": "We monitor and record any potential encroachment issues at our facilities",
       "title": "Clean Air / Land / Water /Biodiversity",
       "id": 52
   },
   {
       "card": "We mitigate any potential land wastage by suitable land restoration methods. ",
       "title": "Clean Air / Land / Water /Biodiversity",
       "id": 53
   },
   {
       "card": "We record and monitor any potential biodiversity loss due to our business operations ",
       "title": "Clean Air / Land / Water /Biodiversity",
       "id": 54
   },
   {
       "card": "We record and monitor any potential marine bodies / coral reefs / mangroves loss due to our business operations ",
       "title": "Clean Air / Land / Water /Biodiversity",
       "id": 55
   },
   {
       "card": "We monitor and record any water wastage in our facilities ",
       "title": "Clean Air / Land / Water /Biodiversity",
       "id": 56
   },
   {
       "card": "We conduct programs aimed at creating awareness on pollution and its mitigation to our employees and other stakeholders on a regular basis",
       "title": "Clean Air / Land / Water /Biodiversity",
       "id": 57
   },
   {
       "card": "We publish any potential environmental threats that our business causes in our product packaging / other means of public communication",
       "title": "Clean Air / Land / Water /Biodiversity",
       "id": 58
   },
   {
       "directions": "Challenger Cards - sort the cards provided into the categories based on which category fits each guideline best. These will be used to draft an 'action plan' at the end of the assessment.",
       "card": "We have an anti-discrimination policy set up  in the organisation ",
       "title": "Human & Animal Rights",
       "id": 59
   },
   {
       "card": "We have a published pay equity policy. ",
       "title": "Human & Animal Rights",
       "id": 60
   },
   {
       "card": "We provide fair employment terms, aligned with human and labour rights, according to the government rules",
       "title": "Human & Animal Rights",
       "id": 61
   },
   {
       "card": "We monitor and record whether we pay our employees at least a minimum wage.",
       "title": "Human & Animal Rights",
       "id": 62
   },
   {
       "card": "We Provide high quality jobs or professional development for employees (workforce development programs)",
       "title": "Human & Animal Rights",
       "id": 63
   },
   {
       "card": "Our business does not harm or abuse animals directly or indirectly",
       "title": "Human & Animal Rights",
       "id": 64
   },
   {
       "card": "We do not allow child labour in our company ",
       "title": "Human & Animal Rights",
       "id": 65
   },
   {
       "card": "We do not allow any forms of slavery or forced employment in our company",
       "title": "Human & Animal Rights",
       "id": 66
   },
   {
       "card": "We provide adequate leisure and holidays to our employees ",
       "title": "Human & Animal Rights",
       "id": 67
   },
   {
       "card": "We follow adequate animal welfare measures in our procurement and operations.",
       "title": "Human & Animal Rights",
       "id": 68
   },
   {
       "card": "We do not engage in wildlife hunting or other inhumane treatment of animals",
       "title": "Human & Animal Rights",
       "id": 69
   },
   {
       "directions": "Challenger Cards - sort the cards provided into the categories based on which category fits each guideline best. These will be used to draft an 'action plan' at the end of the assessment.",
       "card": "We monitor and record diversity statistics, including the board, management , employees and vendors",
       "title": "Diversity,Equity , Inclusion & Social Equity",
       "id": 70
   },
   {
       "card": "We have a goal of at least 50% women in senior management, regardless of organization growth. ",
       "title": "Diversity,Equity , Inclusion & Social Equity",
       "id": 71
   },
   {
       "card": "We engage with diversity consultants to assess and improve our DEI functions including recruitment ",
       "title": "Diversity,Equity , Inclusion & Social Equity",
       "id": 72
   },
   {
       "card": "We have equitable hiring practices and do not descriminate any candidate based on their race, nationality, gender or other factors. ",
       "title": "Diversity,Equity , Inclusion & Social Equity",
       "id": 73
   },
   {
       "card": "We have a clear Employee stock option plan in place ",
       "title": "Diversity,Equity , Inclusion & Social Equity",
       "id": 74
   },
   {
       "card": "We have implemented democratic and transparent decision making processes within the company",
       "title": "Diversity,Equity , Inclusion & Social Equity",
       "id": 75
   },
   {
       "card": "We promote social equity and remove inequities through our business. ",
       "title": "Diversity,Equity , Inclusion & Social Equity",
       "id": 76
   },
   {
       "card": "We protect , acknowledge and support indigenous communities affected through our business ",
       "title": "Diversity,Equity , Inclusion & Social Equity",
       "id": 77
   },
   {
       "card": "We have equitable termination/ layoff practices and communicate the rationale for such decisions to employees affected.",
       "title": "Diversity,Equity , Inclusion & Social Equity",
       "id": 78
   },
   {
       "card": "We provide alternate employment support incase of termination/layoff decisions",
       "title": "Diversity,Equity , Inclusion & Social Equity",
       "id": 79
   },
   {
       "directions": "Challenger Cards - sort the cards provided into the categories based on which category fits each guideline best. These will be used to draft an 'action plan' at the end of the assessment.",
       "card": "We monitor and record workplace health and safety issues.",
       "title": "Societal Health & Health Equity",
       "id": 80
   },
   {
       "card": "We conduct programs aimed at employee health and wellbeing on a regular basis",
       "title": "Societal Health & Health Equity",
       "id": 81
   },
   {
       "card": "We provide parenthood leave and other benefits to our employees ",
       "title": "Societal Health & Health Equity",
       "id": 82
   },
   {
       "card": "We have partnered with quality healthcare provider / insurance provider for medical needs of the employees ",
       "title": "Societal Health & Health Equity",
       "id": 83
   },
   {
       "card": "We engage with our local community through employee / team volunteering works ",
       "title": "Societal Health & Health Equity",
       "id": 84
   },
   {
       "card": "We have partnered with non profits or other organisations providing local/community works and support their works",
       "title": "Societal Health & Health Equity",
       "id": 85
   },
   {
       "card": "We provide healthy and nutritious diet in our workplace instead of junk and unhealthy food. ",
       "title": "Societal Health & Health Equity",
       "id": 86
   },
   {
       "card": "We do not allow any forms of drug abuse in the company and have strict policies to counter the same",
       "title": "Societal Health & Health Equity",
       "id": 87
   },
   {
       "card": "Our workplace provides healthy lifestyle and safety for all. ",
       "title": "Societal Health & Health Equity",
       "id": 88
   },
   {
       "card": "We have a sports/gym/outdoor activity facility at our workplace",
       "title": "Societal Health & Health Equity",
       "id": 89
   },
   {
       "directions": "Challenger Cards - sort the cards provided into the categories based on which category fits each guideline best. These will be used to draft an 'action plan' at the end of the assessment.",
       "card": "Our innovation process includes the negative impact analysis ",
       "title": "Responsible Technologies & Innovation",
       "id": 90
   },
   {
       "card": "Our design processes consist of several iterations to check for any potential harm ",
       "title": "Responsible Technologies & Innovation",
       "id": 91
   },
   {
       "card": "We have incorporated Responsible Innovation Frameworks in our innovation process. ",
       "title": "Responsible Technologies & Innovation",
       "id": 92
   },
   {
       "card": "We have a written code of ethics that promotes ethical decision making ",
       "title": "Responsible Technologies & Innovation",
       "id": 93
   },
   {
       "card": "Our design processes include methods for privacy and security of all concerned ",
       "title": "Responsible Technologies & Innovation",
       "id": 94
   },
   {
       "card": "We iterate the design process for any potential discrimation / biases that the technology can promote",
       "title": "Responsible Technologies & Innovation",
       "id": 95
   },
   {
       "card": "We have a clear ethical decision making hierarchy that looks into matters related to technology ethics",
       "title": "Responsible Technologies & Innovation",
       "id": 96
   },
   {
       "card": "We have clear actionplan / process documents to handle any disaster /crisis response related to the technology that we use or produce ",
       "title": "Responsible Technologies & Innovation",
       "id": 97
   },
   {
       "card": "Our technology promotes digital inclusion / inclusive technology ",
       "title": "Responsible Technologies & Innovation",
       "id": 98
   },
   {
       "card": "We have considered price for our technology product based on digital inclusion and paying capacity of various market / regions",
       "title": "Responsible Technologies & Innovation",
       "id": 99
   }
]

export const challengerLength = challengerSets.length;
export const challengerFirstID = challengerSets[0].id;

export const challengerSections = generateSections();

function generateSections() {
   let arr = [];
   for (let x of challengerSets){
      let s_i = arr.findIndex(v => v.title == x.title);
      if (s_i != -1) arr [s_i].cards.push({ id:x.id, card:x.card});
      else arr.push({title:x.title, cards:[x]});
   }
   return arr;
}