import React from "react";
import { graphcms, QUERY_POSTS } from '../Graphql/Queries'
import Posts from "./Posts";
import Search from "./Search";
import { useRef } from "react";
import "../styles/home.css"
import { Route } from "react-router";
import { useQueryPosts } from "../hooks/useQueryPosts";
import { Loading } from "./Loading";
import { useMyContext } from "./store";
import { ScrollButton } from "./ScrollButton";

export function Blog() {
    const {posts, error } = useQueryPosts({query: QUERY_POSTS})
    const {loading} = useMyContext();
    
    const navlist = useRef();
    if(error) return <h2 style={{textAlign: 'center'}}>{error}</h2>
    return (
        <React.Fragment>
            {loading && <Loading/>}
            <nav id="h-navbar">
                <img id="h-nav-logo" src={require("../static/assets/logo.png")}></img>
                <span className="nav-toggle" onClick={() => navlist.current.classList.toggle("open")}>&#9776;</span>
                <div ref={navlist} id="h-nav-items" className="h-nav-items-blog">
                   
                    <a className="return-link" href="/"> <i className="fa fa-arrow-left" aria-hidden="true"></i> 
                    Return to main page</a>
                </div>

            </nav>
            <Search />
            <Posts posts={posts} />
            <ScrollButton />
        </React.Fragment>
    )
}

