import {  useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as auth from "../services/auth.js"
import "../styles/login.css"
import { LoginPage } from "./loginbtn.js";
import { StartupCreate } from "./startup-create.js";

export function Login() {
   const [authed, setAuthed] = useState(false);
   const navigate = useNavigate()
   document.title = "Turtle Tank | Login"
   useEffect(()=>{
      auth.init().then(()=>{
         if(auth.currentUser){
            handleDone()
         }
      })
   },[])
   

   function handleDone() {
      navigate("/dashboard")
   }

   return (
      <div className="login">
         
         {
            authed ? <StartupCreate show={authed} onceDone={handleDone} /> : <LoginPage show={!authed} onceDone={() => setAuthed(true)} />
         }
      </div>
   )
}