import { useEffect, useRef, useState } from "react"
import { useDrag } from "react-dnd"

export function DragCard({card, newCardID}) {
   const [collected, drag,prev] = useDrag(() => ({
      type:"challenger",
      item: { id:card.id},
      collect: (monitor)=>{
         return {
            isDragging: monitor.isDragging(),
            item:monitor.getItem(),
          }
      }
   }));
   
  
   
   return <div ref={collected.isDragging?prev:drag}  data-card-id={card.id} className={`dragcard ${collected.isDragging?"active":"show"} ${newCardID==card.id?"draggedAnim":""}`}>
         {card.card}
      </div>
   
}