import * as auth from "../services/auth.js"
import logo from '../static/assets/google.png'
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as startup from "../services/startup.js"
import { StartupCreate } from "./startup-create.js";

export function LoginPage({ show, onceDone }) {
   const navigate = useNavigate()
   const [username, setUsername] = useState(""); 
   const [password, setPassword] = useState(""); 
   useEffect(()=>{
      auth.init().then(()=>{
         if(auth.currentUser){
            navigate("/dashboard")
         }
      })
   },[])
   /*
   - Startup name (username)
   - A password
   - Startup Location
   - Cofounder name, email, and phone
   - Startup website
   */
   const [location, setLocation] = useState("");
   const [name, setName] = useState("");
   const [email, setEmail] = useState("");
   const [phone, setPhone] = useState("");
   const [website, setWebsite] = useState("");
   /* */
   const [error, setError] = useState(null);
   const[error2, setError2] = useState(null);
   const [showSignUp, setShowSignUp] = useState(false);
   if (!show) return <></>;
   

   function handleGoogleClick() {
      auth.signIn().then(isNewUser => {
         if (isNewUser) {
            onceDone();
         } else{
            navigate('/dashboard');
         }
      });
   }

   function handleEmailClick () {
      auth.signInWithEmailPassword(username, password)
         .then(currentUser => {
            if (currentUser === null) {
               setError("Invalid Email or Password");
            } else{
               auth.init().then(()=>{
                  if(auth.currentUser){
                     navigate("/dashboard")
                  }
               })
            }
         });
        
   }

   function setShow() {
      setShowSignUp(true);
   }

   function setNotShow(){
      setShowSignUp(false);
   }

   function handleSignUp(){
      auth.signUpWithEmailPassword(username, password)
         .then(currentUser =>{
            if(currentUser === null){
               setError2("Error username already in use");
            } else{
               startup.setStartup(name, "", location, name, email, phone, website);
               navigate("/dashboard")
            }
         })
   }

   return (
      <div className="login-container">
      {showSignUp? 
         <div className = "signup-form">
         <h1>Sign up</h1>
         <div className="input-group">
         <input
               placeholder="Username"
               value={username}
               onChange={(e) => setUsername(e.target.value)}
               className="email-input"
         />
         {error2 && <div className="error-message">{error2}</div>} 
         <input
               type="password"
               placeholder="Password (6+ characters)"
               value={password}
               onChange={(e) => setPassword(e.target.value)}
               className="password-input"
         />

         <input
            placeholder="Startup Location"
            value={location}
            onChange={(e) => setLocation(e.target.value)}
            className="password-input"     
         />

         <input
            placeholder="Cofounder Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="password-input"     
         />

         <input
            placeholder="Cofounder email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="password-input"     
         />

         <input
            placeholder="Cofounder phone"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            className="password-input"     
         />

         <input
            placeholder="Startup website"
            value={website}
            onChange={(e) => setWebsite(e.target.value)}
            className="password-input"     
         />

         <button className="turtle-button-2" onClick={handleSignUp}>
            Sign up
         </button>

         </div>
         </div>
      :
         <>
            <h1>Login</h1>
            <p>Sign in to your account to get started</p>
            <div className="input-group">
               <input
                  placeholder="Username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  className="email-input"
               />
            
               <input
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="password-input"
               />


               <button className="turtle-button" onClick={handleEmailClick}>
                  Login with username
               </button>
               {error && <div className="error-message">{error}</div>} 
            </div>
            
         </>
      }
      
      <div className="separator">
      <div className="line"></div>
      <span>or</span>
      <div className="line"></div>
      </div>                         
      <div className="center">
         <button className="turtle-button"onClick={handleGoogleClick}>Login with Google<img className = "image" src={logo}></img></button> 
      </div>
      

      {showSignUp?
      <p className="sign-up">
         Already have an account? <a className = "sign-up-link" onClick={setNotShow}>Sign in</a>
      </p>:
      <p className="sign-up">
         Don't have an account? <a className = "sign-up-link" onClick={setShow}>Sign up</a>
      </p>
      }
     

      
      </div>
   )
}