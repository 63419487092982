import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { graphcms } from "../Graphql/Queries";
import { useMyContext } from "../blog/store";

export function useQueryPosts({query}){
    const {slug} = useParams();
    const [posts, setPosts] = useState([])
    const [error, setError] = useState(null)

    const {setLoading } = useMyContext();

    useEffect(()=>{
        setLoading(true); 
        graphcms.request(query, {slug})
        .then(res=> {
            setPosts(res.posts)
            setError(res.error)
        }).catch(error => {
            setError(error.message)
            setPosts([])
        })
        .finally(()=>setLoading(false))
    }, [query, slug])

    return {posts, error }
}