import { currentAssessment, finishActionPlan, getAssessments, getChallengerCards, getTurtleCards } from "../../services/assessment";
import { useEffect, useRef, useState } from "react";
import * as startup from "../../services/startup";

import "../../styles/actionplan.css"
import { DocInput } from "./DocInput";
import { useNavigate } from "react-router-dom";
import { challengerFirstID, challengerLength, turtleLength } from "../../services/Data";

export function ActionPlan() {
   const turtleCards = getTurtleCards();
   const challengerCards = getChallengerCards();

   const [ready, setReady] = useState(false);
   const [challengerTodos, setChallengerTodos] = useState([]);
   const [todos, setTodos] = useState([]);

   const navigate = useNavigate();

   useEffect(() => {
      if (!currentAssessment) {
         alert("Please start the assessment first");
         window.location.href = "/dashboard/assessment";
      } else if (currentAssessment.stage != 3) {
         alert("You have already completed this stage");
         window.location.href = "/dashboard/assessment";
      } else {
         setChallengerTodos((currentAssessment.data.stage2.challengerCards.filter((x) => x.status == 1)).map(x => challengerCards[x.id - challengerFirstID]));
         setTodos((currentAssessment.data.stage1.cards.filter((x) => x.score == 5)).map(x => { let card = { ...turtleCards[x.id] }; card.card = card.cards[1].content; delete card.cards; delete card.score; return card }));

         setReady(true)
      }
   }, []);

   function handleClick(i) {
      let navEl = document.querySelector(`[data-nav-id='${i}']`);
      let navCont = navEl.parentElement;
      let newNavPos = navEl.offsetTop - (navCont.getBoundingClientRect().height - navEl.getBoundingClientRect().height) / 2;

      navCont.scrollTo({
         behavior: "smooth",
         top: newNavPos
      })

      let inputEl = document.querySelector(`[data-docinput-id='${i}']`);
      let inputCont = inputEl.parentElement;

      let newInputPos = inputEl.offsetTop - inputCont.offsetTop;

      inputCont.scrollTo({
         behavior: "smooth",
         top: newInputPos
      })

   }

   async function handleDone(e) {
      e.preventDefault();
      var data = new FormData(e.target);
      var result = {}
      data.forEach((x, k) => result[k] = x);
      await finishActionPlan(result);
      await startup.init();
      navigate(`/dashboard/assessment/view/${getAssessments().length - 1}`)
   }
   if (!ready) return <>Loading</>
   return (
      <div className="doc">
         <form className="doc-content" onSubmit={handleDone}>
            <h1>Action Plan</h1>

            <DocInput id="intro" intro="true" content="Introduction"></DocInput>
            {
               todos.concat(challengerTodos).map((x) =>
                  <DocInput id={x.id} content={x.card} title={x.title} key={x.id}></DocInput>
               )
            }
            <button className="turtle-button go">END ASSESSMENT</button>

         </form>
         <div className="doc-nav">
         
         {
            ([{id:"intro",card:"Introduction"}].concat(todos.concat(challengerTodos))).map((x) =>
               <div className="doc-nav-item" data-nav-id={x.id} onClick={() => handleClick(x.id)} key={x.id}>{x.card}</div>
            )}

         </div>

      </div>
   );
}