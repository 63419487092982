import { initializeApp } from "firebase/app";


const firebaseConfig = {
  apiKey: "AIzaSyBqKTyDK-Zp4Btd6fTBKrwkyI8NkwuGpUk",
  authDomain: "turtle-tank-cv.firebaseapp.com",
  projectId: "turtle-tank-cv",
  storageBucket: "turtle-tank-cv.appspot.com",
  messagingSenderId: "1091507683517",
  appId: "1:1091507683517:web:16ad74f8e8cd169e1737d4",
  measurementId: "G-WEKFNGW1ST"
};

export const app = initializeApp(firebaseConfig);

export function init() {
   console.log("Firebase initialized");
}