import { forwardRef, useEffect, useImperativeHandle, useRef } from "react";

export const DocInput = forwardRef(({title, content,id,intro }, ref) => {
   const textarea = useRef();
   
   function resize(){
      textarea.current.style.height = "auto";
      textarea.current.style.height = textarea.current.scrollHeight + "px";
   }

   useEffect(()=>{
      resize();
   },[]);
   return <div data-docinput-id={id} key={content}>
      <h3  className="doc-subtitle">{content}</h3>
      <textarea ref={textarea} onInput={resize} name={id}  className="doc-input" placeholder={intro?"Your intro":`What is your plan to make "${content}" to life`}></textarea>
   </div>

});