import React from 'react';
import ReactDOM from 'react-dom/client';
//import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { Home } from "./static/Home"
import { StartServices } from "./services/start"
import { Dashboard } from './dashboard/Dashboard';
import { Resources } from './resources/Main';
import { Assessment } from './assessment/Assessment';
import { Turtle } from './assessment/turtle-cards/Turtle';
import { Challenger } from './assessment/challenger-cards/Challenger';
import { ActionPlan } from './assessment/action-plan/ActionPlan';

import { Login } from './login/login';
import { ViewAssessment } from './assessment/view/ViewAssessment';
import "./styles/global.css"
import { Profile } from './profile/SelfProfile';
import { StartupProfile } from './profile/StartupProfile';
import {Blog} from './blog/blog'
import {SearchResult} from './blog/SearchResult'  
import { Article } from './blog/Article';
import { Loading } from './blog/Loading';
import { ContextProvider } from './blog/store';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <BrowserRouter>
    <ContextProvider>
      <Routes>
        <Route index element={<Home />} />
        <Route path='login' element={<Login />}/>
        <Route path='blog' element={<Blog/>} />
        <Route path = '/search/:slug' element ={<SearchResult/>} />
        <Route path = '/article/:slug' element = {<Article/>} />
        <Route path='dashboard' element={<StartServices />}>
          <Route index element={<Dashboard />} />

        

          <Route path="profile" element={<Profile></Profile>}></Route>
          <Route path="profile/:uid" element={<StartupProfile/>}></Route>

          <Route path="resources" element={<Resources></Resources>}></Route>
          
          
          <Route path="assessment">
            <Route index element={<Assessment />} />
            <Route path="turtle" element={<Turtle />} />
            <Route path="challenger" element={<Challenger />} />
            <Route path="action-plan" element={<ActionPlan />} />
            <Route path="view/:id" element={<ViewAssessment/>}></Route>
          </Route>
        </Route>
      </Routes>
      </ContextProvider>
    </BrowserRouter>
  // </React.StrictMode> it caused services being init twice
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
