import { getAuth, signInWithPopup, GoogleAuthProvider, createUserWithEmailAndPassword, signInWithEmailAndPassword } from "firebase/auth";
import { app } from "./firebase";
import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { db } from "./db";

const auth = getAuth(app);

export var currentUser = null;

export async function signIn() {
   const provider = new GoogleAuthProvider();
   try {
      await signInWithPopup(auth, provider);
      let isNewUser = true;
      currentUser = auth.currentUser;
      let uid = auth.currentUser.uid;
      const result = await getDoc(doc(db, "startups", uid));
      if (result.exists()) {
         isNewUser = false;
      }
      return isNewUser;
   } catch (error) {
      currentUser = null;
      return false;
   }
}

export async function signInWithEmailPassword(username, password){
   try{  
      const userCredential = await signInWithEmailAndPassword(auth, username + "@yourdomain.com", password);
      currentUser = userCredential.user;
      return currentUser;
   } catch (error){
      currentUser = null;
      return currentUser
   }
}

export async function signUpWithEmailPassword(username, password){
   try {
      const userCredential = await createUserWithEmailAndPassword(auth, username + "@yourdomain.com", password);
      currentUser = userCredential.user;
      return currentUser
   } catch (error){
      console.log(error);
      currentUser = null;
      return currentUser
   }
}

export function init() {
   return new Promise((resolve, reject) => {
      auth.onAuthStateChanged(user => {
         if (user) {
            console.log("user signed in");
            currentUser = user;
            resolve(user);
         } else {
            console.log("user signed out");
            currentUser = null;
            resolve(null);
         }
      });
   })
}


export function logout() {
   auth.signOut();
   window.location.reload();
}

