import { currentAssessment, finishTurtle, getTurtleCards } from "../../services/assessment";
import { CardSet } from "./CardSet";
import { useEffect, useState } from "react";
import "../../styles/turtle.css";
import { useNavigate } from "react-router-dom";

export function Turtle() {
   const [score, setScore] = useState(0);
   const [cardIndex, setCardIndex] = useState(0);
   const [selectedCards, setCards] = useState([]);
   const cards = getTurtleCards();
   const navigate = useNavigate();

   useEffect(() => {
      if (!currentAssessment) {
         alert("Please start the assessment first");
         window.location.href = "/dashboard/assessment";
      }else if(currentAssessment.stage != 1){
         alert("You have already completed this stage");
         window.location.href = "/dashboard/assessment";
      }
   }, [])

   function handleDone(cardScore) {
      const newScore = score + cardScore;
      const newCards = [...selectedCards, { id: cards[cardIndex].id, score: cardScore }];

      setScore(newScore);
      setCards(newCards);
      
      if (cardIndex != cards.length - 1) setCardIndex(cardIndex + 1)
      else {
         finishTurtle(newScore, newCards);
         navigate("/dashboard/assessment/challenger");
      };
   };
   return (
      <>
      <h3 id="title">{cards[cardIndex].title}
         <br/>
         <span style={{
            color:"var(--theme-text-3)",
            fontSize:"15px",
            textTransform: "initial"
         }}>Click to choose the relevant card</span>
         
      </h3>
      
      

      

      

      <CardSet cards={cards[cardIndex].cards}  onceDone={handleDone}></CardSet>
      </>
   );
}