import { useDragDropManager, useDrop } from 'react-dnd'
import { DragCard } from './DragCard';
import { useCallback, useEffect, useRef, useState } from 'react';


export function DropBar({ cards, onItem, title, subtitle }) {
   const scrollRef = useRef(null);
   const dragDropManager = useDragDropManager();
   const monitor_global = dragDropManager.getMonitor();

   const [newCardIndex, setNewCardIndex] = useState(0);
   //fuck react in all posible  positions
   const NCI = useRef();
   NCI.current = newCardIndex;
   const cardsRef = useRef();
   cardsRef.current = cards;
   const [newCardID, setNewCardID] = useState(-1);

   useEffect(() => monitor_global.subscribeToOffsetChange(() => {
      let ells = document.querySelectorAll('.dragcard.up')
      let ells2 = document.querySelectorAll('.dragcard.down')

      function clear(){
         ells.forEach(e => (e.classList.remove("up")));
         ells2.forEach(e => (e.classList.remove("down")))
      }
      let off = monitor_global.getClientOffset();
      if (!off) clear();
      if (!off) return;
      let { x, y } = off;
      const item = monitor_global.getItem();
      if (!item) clear();
      if (!item) return;
      let el = document.elementFromPoint(x, y);
      
      if (!el.classList.contains("dragcard")) {
         if (el.classList.contains("dropbar")){
            setNewCardIndex(cardsRef.current.length);
            clear();
            let cs = el.getElementsByClassName("dragcard");
            if (cs.length > 0) cs.item(cs.length - 1).classList.add("down")
         }
         
         return;
      };
      
      let bounds = el.getBoundingClientRect();
      
      let middleY = bounds.top + bounds.height / 2;
      clear();

      if (y < middleY) {
         el.classList.add("up");
         let prevEl= el.previousElementSibling;
         if (prevEl && prevEl.dataset.cardId == item.id) {
            prevEl = prevEl.previousElementSibling;
         }
         if (prevEl){
            prevEl.classList.add("down");

         }
      }
      else {
         el.classList.add("down");
         let nextEl= el.nextElementSibling;
         if (nextEl && nextEl.dataset.cardId == item.id) {
            nextEl = nextEl.nextElementSibling;
         }
         if (nextEl){
            nextEl.classList.add("up");

         }
      };
      let cardId = el.dataset.cardId;

      let cardIndex = cardsRef.current.findIndex(c => c.id == cardId);

      if (cardIndex > -1) {
         let dci = cardsRef.current.findIndex(c => c.id == item.id);
         let _nci = cardIndex;
         if (y > middleY){
            _nci++
         }
         if (dci > -1) {
            if(_nci > dci)_nci--;
         }
         setNewCardIndex(_nci);
      }


   }), [monitor_global]);


   function handleDrop(item) {
      onItem(item.id, NCI.current)
      setNewCardID(item.id);
   }
   const [collectedProps, drop] = useDrop(() => ({
      accept: "challenger",
      drop: handleDrop,
      collect: (monitor) => ({
         isOver: monitor.isOver(),
         canDrop: monitor.canDrop()
      }),



   }))


   return <>
      <div className={`dropbar `} ref={drop}>
         <div style={{ borderBottom: "1px solid var(--theme-border)", padding: "10px 0px" }}>
            <h3 style={{ textAlign: "center", margin: "0 25px" }}>{title}</h3>
            <h6 className='bar-subtitle' style={{ display: !subtitle ? "none" : "block" }}>{subtitle}</h6>

         </div>
         <div ref={scrollRef} className='dragItems'>
            {cards.map((x) => <DragCard newCardID={newCardID} key={x.id} card={x}></DragCard>)}
         </div>
      </div>
   </>
}
