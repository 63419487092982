import { GraphQLClient, gql, } from 'graphql-request'

export const graphcms = new GraphQLClient(process.env.REACT_APP_API)

const category = `
  id, 
  name, 
  slug, 
  color { css },
`

const comment = `
  id, 
  name,
  email,
  comment
`

const post = `
  id, 
  title,
  slug,
  coverPhoto { url },
  content { html },
  description,
  createdAt,
  updatedAt,

`

export const QUERY_POSTS = gql`
    {
        posts(orderBy: updatedAt_DESC){
            ${post}
            categories(){
                ${category}
            }
        }
    }
`
export const QUERY_SEARCH_POSTS = gql`
  query GetSearchPosts($slug: String!) {
    posts(
      orderBy: updatedAt_DESC,
      where: {_search: $slug}
    ){
      ${post}
      categories(){
        ${category}
      }
    }
  }
`

export const QUERY_ONE_POST = gql`
  query GetOnePost($slug: String!) {
    posts(where: {slug: $slug}) {
      ${post}
      categories(){
        ${category}
      }
      comments(orderBy: updatedAt_DESC){
        ${comment}
      }
    }
  }
`