import { useState } from "react";
import { useEffect } from "react";

export function ImageCycle({ images }) {
   const [index, setIndex] = useState(0);

   useEffect(() => {
      const interval = setInterval(() => {
         setIndex((index + 1) % images.length);
      }, 5000);
      return () => clearInterval(interval);
   }, [index]);

function prev(){
   if (index > 0) {
      setIndex(index - 1);
   }else{
      setIndex(2);
   }
}

function next(){
   if (index < 2) {
      setIndex(index + 1);
   }else{
      setIndex(0);
   }
}

   return (
      <div className="image-cycle">
         <div className="inactive-cont">
            <img className="inactive" src={index > 0 ? images[index - 1] : images[2]}></img>
            <button className="image-btn" onClick={prev}>&lt;</button>
         </div>

         <img src={images[index]} ></img>
         <div className="inactive-cont">
            <img className="inactive" src={index < 2 ? images[index + 1] : images[0]}></img>
            <button className="image-btn" onClick={next}>&gt;</button>
         </div>

      </div>
   )
}